<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :is-sidebar-active.sync="isSidebarActive"
      :show.sync="show"
      :data="sidebarData"
    />
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t("entries") }}</label>
            </b-col>

            <b-col cols="12" md="6" class="b-col-custom">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
        >
          <!-- Column: Images -->
          <template #cell(member)="data">
            <b-row>
              <div class="d-flex align-items-center">
                <b-avatar
                  v-if="data.item.image"
                  size="40"
                  variant="light-primary"
                  :src="data.item.image"
                  class="mr-1"
                />
                <b-avatar
                  v-else
                  size="40"
                  variant="light-primary"
                  :src="require('@/assets/images/avatars/13-small.png')"
                  class="mr-1"
                />
                <div class="d-sm-flex d-none flex-column">
                  <p class="mb-0">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </p>
                  <span class="d-block">{{ data.item.userId.username }}</span>

                  <b-badge :variant="'primary'" class="">
                    <span class="d-block">
                      {{ $t("Member Level") }}:
                      {{ selectLevel(data.item.userId.memberLevelId) }}</span
                    >
                  </b-badge>
                </div>
              </div>
            </b-row>
          </template>

          <template #cell(workFlow)="data">
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="align-middle text-body"
              @click="viewWorkFlow(data.item._id)"
            />
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import perPageOptions from "@/perPageOptions";
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { ref } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
import storeModule from "@/store/services/eCommerce/memberInvitation";
import storeModuleMemberLevel from "@/store/services/eCommerce/memberLevel";

import debounce from "debounce";
import Sidebar from "./DataSidebar.vue";

const STORE_MODULE_NAME = "memberInvitation";
const STORE_MODULE_NAME_MEMBER_LEVEL = "memberLevel";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    Sidebar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BBadge,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: "",
      perPage: localStorage.getItem("itemsPerPage")
        ? localStorage.getItem("itemsPerPage")
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      perPageOptions,
    };
  },
  computed: {
    tableColumns() {
      const memberLevel = this.respDataMemberLevel;
      return [
        { key: "member", label: this.$t("Member"), sortable: false },
        ...memberLevel,
        { key: "workFlow", label: this.$t("Work flow"), sortable: false },
      ];
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : [];
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0;
    },
    respDataMemberLevel() {
      return store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData != null
        ? store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData.data.map(
            (e) => ({
              key: e._id,
              label: `${this.showFromCurrentLanguage(e.name)}`,
              sortable: false,
            })
          )
        : [];
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME))
      store.registerModule(STORE_MODULE_NAME, storeModule);
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL))
      store.registerModule(
        STORE_MODULE_NAME_MEMBER_LEVEL,
        storeModuleMemberLevel
      );
    this.getMemberLevel();
    this.get();

    this.typing = debounce(this.typing, 500);
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME))
      store.unregisterModule(STORE_MODULE_NAME);
    if (store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL))
      store.unregisterModule(STORE_MODULE_NAME_MEMBER_LEVEL);
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_MEMBER_LEVEL };
  },
  methods: {
    viewWorkFlow(id) {
      this.$router.push({
        name: "e-commerce-member-invitation-workflow",
        params: {
          id,
        },
      });
    },
    getMemberLevel() {
      const obj = {
        currentPage: 0,
        pageSize: 0,
        searchQuery: "",
      };
      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME_MEMBER_LEVEL}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          this.show = false;

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          this.show = false;
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      };
      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          this.show = false;

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          this.show = false;
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    typing(value) {
      console.log(value);
      this.searchQuery = value;
      this.get();
    },
    sidebarAddNew() {
      this.sidebarData = {};
      this.isSidebarActive = true;
    },
    sidebarEdit(item) {
      this.sidebarData = item;
      this.isSidebarActive = true;
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to delete")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.show = true;
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, { id })
              // eslint-disable-next-line no-unused-vars
              .then((result) => {
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    pageSize(size) {
      localStorage.setItem("itemsPerPage", size);
      this.perPage = size;
      this.currentPage = 1;

      this.get();
    },
    nextPage(page) {
      this.currentPage = page;
      this.get();
    },
    selectLevel(id) {
      if (id !== null) {
        const index = this.respDataMemberLevel.findIndex((e) => e.key === id);
        if (index > -1) {
          return this.respDataMemberLevel[index].label;
        }
        return this.$t("No matching records found");
      }
      return this.$t("Didn't choose");
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex((e) => e.lang === this.$i18n.locale);
      if (indexLang > -1) {
        return data[indexLang].value;
      }
      return "";
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
